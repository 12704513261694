import { useFormik } from 'formik'
import React, { FC } from 'react'
import { createReferralWithdraw, CreateReferralWithdrawPayload } from '../../../api/referral/refferal'
import { useAccount } from '../../../hooks/useAccount'
import { useReferralWithdraws } from '../../../hooks/useReferralWithdraws'
import { SweetAlert } from '../../../utilities/sweetAlert'

interface CreateReferralWithdrawDialogProps {
	onClose: () => void
}
const CreateReferralWithdrawDialog: FC<CreateReferralWithdrawDialogProps> = ({ onClose }) => {
	const { requestAccount } = useAccount()
	const { mutateReferralWithdraws } = useReferralWithdraws()

	const { isSubmitting, handleChange, handleSubmit } = useFormik<CreateReferralWithdrawPayload>({
		initialValues: {
			amount: 500,
			bankCode: '',
			bankNumber: '',
		},
		onSubmit: async (values) => {
			values.amount = Number(values.amount)

			try {
				const res = await createReferralWithdraw(values)

				SweetAlert.success(res.message).then(() => {
					requestAccount()
					mutateReferralWithdraws()

					onClose()
				})
			} catch ({ message }) {
				SweetAlert.error(message)
			}
		},
	})

	return (
		<div className="dialog add-subscription-dialog">
			<div className="dialog-wrapper">
				<div className="dialog-header">
					<h3>ถอนยอดคอมมิชชั่น</h3>
				</div>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<div className="dialog-content">
						<div className="mb-3">
							<label htmlFor="amount" className="form-label">
								จำนวนเงิน
							</label>
							<input
								type="number"
								name="amount"
								id="amount"
								className="form-control"
								placeholder="จำนวนเงิน"
								required
								onChange={handleChange}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="bankCode" className="form-label">
								ธนาคาร
							</label>
							<select name="bankCode" id="bankCode" className="form-select" required onChange={handleChange}>
								<option value="">--- เลือกธนาคาร ---</option>
								<option value="002">ธนาคารกรุงเทพ (BBL)</option>
								<option value="004">ธนาคารกสิกรไทย (KBANK)</option>
								<option value="006">ธนาคารกรุงไทย (KTB)</option>
								<option value="011">ธนาคารทหารไทยธนชาต (TTB)</option>
								<option value="014">ธนาคารไทยพาณิชย์ (SCB)</option>
								<option value="022">ธนาคารซีไอเอ็มบี (CIMB)</option>
								<option value="025">ธนาคารกรุงศรีอยุธยา (BAY)</option>
								<option value="030">ธนาคารออมสิน (GSB)</option>
							</select>
						</div>
						<div className="mb-3">
							<label htmlFor="amount" className="form-label">
								เลขที่บัญชี
							</label>
							<input
								type="text"
								name="bankNumber"
								id="bankNumber"
								className="form-control"
								placeholder="เลขที่บัญชี"
								required
								onChange={handleChange}
							/>
						</div>
						<div className="row mt-4">
							<div className="col">
								<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
									ยืนยัน
								</button>
							</div>
							<div className="col">
								<button type="button" className="btn btn-light" onClick={() => !isSubmitting && onClose()}>
									ยกเลิก
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default CreateReferralWithdrawDialog
