import React, { FC } from 'react'
import { useAccount } from '../../hooks/useAccount'
import NotificationsMiddleware from '../../middlewares/NotificationsMiddleware/NotificationsMiddleware'
import Navbar from './Navbar'

const AppLayout: FC = ({ children }) => {
	const { account, signOut } = useAccount()

	if (!account.data) {
		return null
	}
	return (
		<NotificationsMiddleware>
			<Navbar account={account.data} signOut={signOut} />

			<div className="container">
				<main>{children}</main>
				<footer className="footer mt-5 text-center">
					<h6>UPLIVE-TH</h6>
					<h6>
						Copyright &copy; <b>2021</b> All Rights Reserved.
					</h6>
				</footer>
			</div>
		</NotificationsMiddleware>
	)
}

export default AppLayout
