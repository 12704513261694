import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { FC } from 'react'
import { addSubscription } from '../../../api/subscription/subscription'
import { useSubscriptions } from '../../../hooks/useSubscriptions'
import { format } from '../../../utilities/number'
import { SweetAlert } from '../../../utilities/sweetAlert'

interface AddSubscriptionDialogProps {
	onClose: () => void
}
const AddSubscriptionDialog: FC<AddSubscriptionDialogProps> = ({ onClose }) => {
	const { subscriptionOptions, mutateSubscriptions } = useSubscriptions()
	const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik({
		initialValues: {
			link: '',
			optionID: '',
			duration: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await addSubscription({
					link: values.link,
					optionID: parseInt(values.optionID),
					duration: parseInt(values.duration),
				})

				SweetAlert.success(res.message).then(() => {
					mutateSubscriptions()
					onClose()
				})
			} catch ({ message }) {
				SweetAlert.error(message)
			}
		},
	})

	if (!subscriptionOptions) {
		return null
	}

	const selectedOption = subscriptionOptions.find((opt) => opt.id === parseInt(values.optionID))
	const selectedDuration = selectedOption?.durations.find((opt) => opt.duration === parseInt(values.duration))
	return (
		<div className="dialog add-subscription-dialog">
			<div className="dialog-wrapper">
				<div className="dialog-header">
					<h3>เพิ่มบริการรายเดือน</h3>
				</div>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<div className="dialog-content">
						<div className="mb-3">
							<label htmlFor="link" className="form-label">
								ลิงก์โปรไฟล์
							</label>
							<input
								type="url"
								name="link"
								id="link"
								className="form-control"
								placeholder="https://www.facebook.com/..."
								required
								onChange={handleChange}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="optionID" className="form-label">
								บริการ
							</label>
							<select
								name="optionID"
								id="optionID"
								className="form-select"
								required
								onChange={handleChange}
							>
								<option>--- เลือกบริการ ---</option>
								{subscriptionOptions.map((option) => (
									<option key={`services-${option.id}`} value={option.id}>
										{option.serviceName}
									</option>
								))}
							</select>
						</div>
						{selectedOption && (
							<div className="subscription-duration-selector mb-3">
								<div className="subscription-duration-text">ระยะเวลา</div>
								<div className="subscription-duration-options">
									{selectedOption.durations.map((opt) => (
										<div
											key={`durations-${opt.duration}`}
											className={clsx('subscription-duration-options-item', {
												active: parseInt(values.duration) === opt.duration,
											})}
											onClick={() => setFieldValue('duration', opt.duration)}
										>
											<b>{opt.duration}</b> วัน
										</div>
									))}
								</div>
							</div>
						)}
						{selectedDuration && (
							<div className="mb-3">
								<label htmlFor="charges" className="form-label">
									ค่าบริการ
								</label>
								<input
									type="text"
									name="charges"
									id="charges"
									className="form-control"
									value={format(selectedDuration.charges)}
									readOnly
								/>
							</div>
						)}
						<div className="row mt-3">
							<div className="col">
								<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
									ยืนยัน
								</button>
							</div>
							<div className="col">
								<button
									type="button"
									className="btn btn-light"
									onClick={() => !isSubmitting && onClose()}
								>
									ยกเลิก
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default AddSubscriptionDialog
