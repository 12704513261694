import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { generateBankQRCode } from '../../../api/topup/topup'
import { SweetAlert } from '../../../utilities/sweetAlert'
import BankTopupQRCodeDialog from './BankTopupQRCodeDialog'

const BankTopup = () => {
	const [topupQRCode, setTopupQRCode] = useState('')
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			amount: '100',
		},
		onSubmit: async (values) => {
			try {
				const res = await generateBankQRCode({ amount: parseFloat(values.amount) })

				setTopupQRCode(res.data)
			} catch ({ message }) {
				SweetAlert.error(message)
			}
		},
	})

	return (
		<Fragment>
			<div className="card">
				<div className="card-header">
					เติมเงินผ่านธนาคาร <small>(QR Code)</small>
				</div>
				<div className="card-body">
					<form autoComplete="off" onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="amount" className="form-label">
								จำนวนเงิน
							</label>
							<input
								type="number"
								name="amount"
								id="amount"
								className="form-control"
								placeholder="จำนวนเงิน"
								min={100}
								defaultValue={values.amount}
								required
								onChange={handleChange}
							/>
						</div>
						<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
							เติมเงิน
						</button>
					</form>
				</div>
			</div>

			{topupQRCode && <BankTopupQRCodeDialog data={topupQRCode} onClose={() => setTopupQRCode('')} />}
		</Fragment>
	)
}

export default BankTopup
