import firebase from 'firebase/app'
import 'firebase/auth'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import LoadingPage from '../../components/LoadingPage'
import { useAccount } from '../../hooks/useAccount'

const AuthMiddleware: FC = ({ children }) => {
	const { account, requestAccount } = useAccount()
	const accountLoadingOrLoaded = account.loading || account.data || account.lastUpdated !== 0

	const history = useHistory()
	const [showChildren, setShowChildren] = useState(false)
	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged(async (state) => {
			if (!state) {
				history.push('/sign-in')
			} else {
				if (!accountLoadingOrLoaded) {
					try {
						await requestAccount()

						setShowChildren(true)
					} catch (error) {
						await firebase.auth().signOut()

						console.error(error)
						history.push('/sign-in')
					}
				} else {
					setShowChildren(true)
				}
			}
		})

		return () => {
			unsubscribe()
		}
	}, [history, accountLoadingOrLoaded, requestAccount])

	if (!showChildren || (!account.data && account.loading)) {
		return <LoadingPage />
	}
	return <Fragment>{children}</Fragment>
}

export default AuthMiddleware
