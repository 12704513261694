import React from 'react'
import BankTopup from './BankTopup'
import TopupTransactions from './TopupTransactions'

const Topup = () => {
	return (
		<div className="page-content">
			<div className="page-header">
				<div className="page-title">
					<h3>
						<i className="fad fa-usd-circle" /> &nbsp;เติมเงิน
					</h3>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<BankTopup />
				</div>
				<div className="col-md-6">
					<TopupTransactions />
				</div>
			</div>
		</div>
	)
}

export default Topup
